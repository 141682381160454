import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { FC } from "react";
import NewEventModal from "./NewEventModal";
import { Event } from "../../../../config/firebase";

export interface NewEventButtonProps {
  defaultDate: Date;
  events?: Event[];
}

const NewEventButton: FC<NewEventButtonProps> = ({ defaultDate, events }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      {opened && (
        <NewEventModal
          opened={opened}
          close={close}
          defaultDate={defaultDate}
          events={events}
        />
      )}
      <Button leftSection={<IconPlus size={18} />} onClick={open}>
        Nouvel événement
      </Button>
    </>
  );
};

export default NewEventButton;
