import {
  Accordion,
  ActionIcon,
  Button,
  Card,
  CopyButton,
  Group,
  Image,
  Input,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconBrandAppleFilled,
  IconBrandGoogleFilled,
  IconBrandWindows,
  IconCheck,
  IconCopy,
  IconDeviceMobile,
  IconX,
} from "@tabler/icons-react";
import google1 from "./google-1.png";
import google2 from "./google-2.png";
import google3 from "./google-3.png";
import outlook1 from "./outlook-1.png";
import outlook2 from "./outlook-2.png";
import mac1 from "./mac-1.png";
import mac2 from "./mac-2.png";
import iphone1 from "./iphone-1.jpg";
import iphone2 from "./iphone-2.jpg";

const CALENDAR_URL =
  "https://europe-west3-valdisere-calendar.cloudfunctions.net/ical";

const Sync = () => {
  return (
    <Card className="max-w-2xl mx-auto p-4" withBorder>
      <Stack gap="md">
        <div className="flex gap-4">
          <Title order={3}>
            Comment synchroniser les évènements avec mon calendrier ?
          </Title>
          <ActionIcon
            variant="default"
            size="lg"
            component="a"
            href={window.location.origin}
          >
            <IconX size={18} />
          </ActionIcon>
        </div>
        <Text ta="justify">
          Copiez le lien du calendrier ci-dessous et suivez les instructions
          selon votre service de calendrier.
        </Text>
        <Input.Wrapper label="Lien du calendrier">
          <Group>
            <TextInput
              variant="filled"
              value={CALENDAR_URL}
              readOnly
              flex={1}
            />
            <CopyButton value={CALENDAR_URL}>
              {({ copied, copy }) => (
                <Button
                  color={copied ? "teal" : "blue"}
                  onClick={copy}
                  leftSection={
                    copied ? <IconCheck size={18} /> : <IconCopy size={18} />
                  }
                >
                  {copied ? "Copié" : "Copier"}
                </Button>
              )}
            </CopyButton>
          </Group>
        </Input.Wrapper>
        <Input.Wrapper label="Instructions">
          <Accordion variant="contained">
            <Accordion.Item value="google">
              <Accordion.Control
                icon={
                  <ThemeIcon variant="transparent" color="red">
                    <IconBrandGoogleFilled />
                  </ThemeIcon>
                }
              >
                Google Calendar
              </Accordion.Control>
              <Accordion.Panel>
                <Stack>
                  <Text ta="justify">
                    Dans Google Calendar, cliquez sur le "<b>+</b>" à côté de "
                    <b>Autres agendas</b>" et sélectionnez "
                    <b>À partir de l'URL</b>". Collez le lien copié, puis
                    cliquez sur "<b>Ajouter un agenda</b>".
                  </Text>
                  <Text ta="justify">
                    Veillez à ne pas cocher l'option "
                    <b>Rendre l'agenda accessible en mode public</b>".
                  </Text>
                  <Image
                    src={google1}
                    radius="md"
                    alt="Étape 1 Google Calendar"
                    m="auto"
                    className="border border-solid border-gray-300"
                  />
                  <Image
                    src={google2}
                    radius="md"
                    alt="Étape 2 Google Calendar"
                    m="auto"
                    className="border border-solid border-gray-300"
                  />
                  <Image
                    src={google3}
                    radius="md"
                    alt="Étape 3 Google Agenda"
                    m="auto"
                    className="border border-solid border-gray-300"
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="outlook">
              <Accordion.Control
                icon={
                  <ThemeIcon variant="transparent" color="blue">
                    <IconBrandWindows />
                  </ThemeIcon>
                }
              >
                Outlook
              </Accordion.Control>
              <Accordion.Panel>
                <Stack>
                  <Text ta="justify">
                    Dans Outlook, cliquez sur "<b>Ajouter un calendrier</b>" et
                    sélectionnez "<b>S'abonner à partir du web</b>". Collez le
                    lien copié, donnez un nom à ce calendrier (exemple : "
                    <i>Sayer - Calendrier Val d'Isère</i>"), puis cliquez sur "
                    <b>Importer</b>".
                  </Text>
                  <Image
                    src={outlook1}
                    radius="md"
                    alt="Étape 1 Outlook"
                    m="auto"
                    className="border border-solid border-gray-300"
                  />
                  <Image
                    src={outlook2}
                    radius="md"
                    alt="Étape 2 Outlook"
                    m="auto"
                    className="border border-solid border-gray-300"
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="mac">
              <Accordion.Control
                icon={
                  <ThemeIcon variant="transparent" color="gray">
                    <IconBrandAppleFilled />
                  </ThemeIcon>
                }
              >
                Mac
              </Accordion.Control>
              <Accordion.Panel>
                <Stack>
                  <Text ta="justify">
                    Depuis l'application Calendrier de votre Mac, allez dans "
                    <b>Fichier {">"} Abonnement à un calendrier</b>". Collez le
                    lien copié, puis cliquez sur "<b>S'abonner</b>".
                  </Text>
                  <Image
                    src={mac1}
                    radius="md"
                    alt="Étape 1 Mac Calendar"
                    m="auto"
                    className="border border-solid border-gray-300"
                  />
                  <Image
                    src={mac2}
                    radius="md"
                    alt="Étape 2 Mac Calendar"
                    m="auto"
                    className="border border-solid border-gray-300"
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="iphone">
              <Accordion.Control
                icon={
                  <ThemeIcon variant="transparent" color="green">
                    <IconDeviceMobile />
                  </ThemeIcon>
                }
              >
                iPhone
              </Accordion.Control>
              <Accordion.Panel>
                <Stack>
                  <Text ta="justify">
                    Depuis l'application Calendrier de votre iPhone, cliquez sur
                    "<b>Nouv. calendrier</b>" puis "
                    <b>Ajouter un calendrier avec abonnement</b>". Collez le
                    lien copié, puis cliquez sur "<b>S'abonner</b>".
                  </Text>
                  <Image
                    src={iphone1}
                    radius="md"
                    alt="Étape 1 iPhone Calendar"
                    m="auto"
                    className="border border-solid border-gray-300 max-w-64"
                  />
                  <Image
                    src={iphone2}
                    radius="md"
                    alt="Étape 2 iPhone Calendar"
                    m="auto"
                    className="border border-solid border-gray-300 max-w-64"
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Input.Wrapper>
      </Stack>
    </Card>
  );
};

export default Sync;
