import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Event } from "../../../../../config/firebase";
import { FC } from "react";
import EditEventModal from "./EditEventModal";

export interface EditEventButtonProps {
  event: Event;
  events?: Event[];
}

const EditEventButton: FC<EditEventButtonProps> = ({ event, events }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      {opened && (
        <EditEventModal
          opened={opened}
          close={close}
          event={event}
          events={events}
        />
      )}
      <Button size="xs" variant="light" onClick={open} className="flex-1">
        Modifier
      </Button>
    </>
  );
};

export default EditEventButton;
