export const joinStrings = (strings: string[]) => {
  return strings.join(", ").replace(/, ([^,]*)$/, " & $1");
};

export const cleanTitle = (input: string): string => {
  return input
    .trim()
    .replace(/\s+/g, " ")
    .replace(/^([a-z])/, (match) => match.toUpperCase());
};
