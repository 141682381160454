import {
  useSignInWithGoogle,
  useSignInWithMicrosoft,
} from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { Button, Card, Divider, Stack } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

const Auth = () => {
  const [signInWithGoogle, , loadingGoogle] = useSignInWithGoogle(auth);
  const [signInWithMicrosoft, , loadingMicrosoft] =
    useSignInWithMicrosoft(auth);

  return (
    <div className="flex items-center justify-center h-full">
      <Card withBorder>
        <Stack className="flex items-center justify-center">
          <Button
            size="md"
            color="red"
            rightSection={<IconArrowRight size={18} />}
            onClick={() => signInWithGoogle()}
            loading={loadingGoogle}
          >
            Se connecter avec Google
          </Button>
          <Divider label="ou" w="128px" />
          <Button
            size="md"
            color="blue"
            rightSection={<IconArrowRight size={18} />}
            onClick={() => signInWithMicrosoft()}
            loading={loadingMicrosoft}
          >
            Se connecter avec Microsoft
          </Button>
        </Stack>
      </Card>
    </div>
  );
};

export default Auth;
