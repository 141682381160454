import { Calendar } from "@mantine/dates";
import dayjs from "dayjs";
import { FC } from "react";
import { Event } from "../../../../config/firebase";
import { Card, LoadingOverlay } from "@mantine/core";
import { maxDate } from "../../../../config/date";
import clsx from "clsx";

export interface CalendarCardProps {
  events?: Event[];
  loading: boolean;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  calendarDate: Date;
  setCalendarDate: React.Dispatch<React.SetStateAction<Date>>;
}

const CalendarCard: FC<CalendarCardProps> = ({
  events,
  loading,
  selectedDate,
  setSelectedDate,
  calendarDate,
  setCalendarDate,
}) => {
  return (
    <Card withBorder>
      <LoadingOverlay
        visible={loading}
        zIndex={1}
        overlayProps={{ blur: 2 }}
        loaderProps={{
          type: "bars",
        }}
      />
      <Calendar
        getDayProps={(date) => {
          const selected = dayjs(date).isSame(selectedDate, "date");

          return {
            selected,
            onClick: () => setSelectedDate(date),
            style: selected
              ? {
                  borderRadius: "unset",
                }
              : {},
          };
        }}
        level="month"
        onLevelChange={() => {
          setSelectedDate(new Date());
          setCalendarDate(new Date());
        }}
        minDate={dayjs().subtract(maxDate.value, maxDate.unit).toDate()}
        maxDate={dayjs().add(maxDate.value, maxDate.unit).toDate()}
        date={calendarDate}
        onDateChange={setCalendarDate}
        renderDay={(date) => {
          const day = date.getDate();

          if (dayjs(date).isSame(selectedDate, "date")) {
            return <div>{day}</div>;
          }

          const dayEvents = events?.filter((event) => {
            const formattedDate = dayjs(date).format("YYYY-MM-DD");

            return (
              !event?.deletedAt &&
              event?.startDate &&
              event?.endDate &&
              formattedDate >= event.startDate &&
              formattedDate <= event.endDate
            );
          });

          if (loading || !dayEvents?.length) {
            return <div>{day}</div>;
          }

          return (
            <div
              className={clsx(
                "w-full h-full flex items-center justify-center",
                {
                  "bg-[#228be61f]": dayEvents.length === 1,
                  "bg-[#228be63e]": dayEvents.length === 2,
                  "bg-[#228be65d]": dayEvents.length > 2,
                },
              )}
            >
              <div>{day}</div>
            </div>
          );
        }}
      />
    </Card>
  );
};

export default CalendarCard;
