import { ActionIcon, Button, Group, Menu, Text } from "@mantine/core";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { auth } from "../../../config/firebase";
import { IconCalendarPlus, IconPower, IconUser } from "@tabler/icons-react";

const Header = () => {
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);

  return (
    <div className="flex items-center justify-between h-full px-4">
      <Text component="a" href={window.location.origin}>
        <b>Sayer family</b> • Calendrier Val d'Isère
      </Text>
      <Group gap="xs">
        <Button
          component="a"
          href={window.location.origin + "/sync"}
          size="xs"
          variant="default"
          leftSection={<IconCalendarPlus size={18} />}
          visibleFrom="sm"
        >
          Ajouter à mon calendrier
        </Button>
        <ActionIcon
          component="a"
          href={window.location.origin + "/sync"}
          variant="default"
          hiddenFrom="sm"
        >
          <IconCalendarPlus size={18} />
        </ActionIcon>
        <Menu withinPortal>
          <Menu.Target>
            <ActionIcon variant="light">
              <IconUser size={18} />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            {user?.email && <Menu.Label>{user.email}</Menu.Label>}
            <Menu.Item
              color="red"
              leftSection={<IconPower size={18} />}
              onClick={signOut}
            >
              Se déconnecter
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </div>
  );
};

export default Header;
