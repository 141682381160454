import { AppShell } from "@mantine/core";
import Header from "./Header";
import Main from "./Main";
import Sync from "./Sync";

const Home = () => {
  const pathname = window.location.pathname;

  return (
    <AppShell header={{ height: 60 }} padding="md">
      <AppShell.Header>
        <Header />
      </AppShell.Header>

      <AppShell.Main>
        {pathname.startsWith("/sync") ? <Sync /> : <Main />}
      </AppShell.Main>
    </AppShell>
  );
};

export default Home;
