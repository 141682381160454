import dayjs from "dayjs";
import { FC } from "react";
import { Event } from "../../../../config/firebase";
import {
  Card,
  Divider,
  Group,
  HoverCard,
  Indicator,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { orderBy, sortBy } from "lodash";
import EditEventButton from "./EditEventButton";
import { IconBed, IconFriends, IconInfoCircle } from "@tabler/icons-react";
import { joinStrings } from "../../../../utils/lib";

export interface EventsListProps {
  events?: Event[];
  selectedDate: Date;
}

const EventsList: FC<EventsListProps> = ({ events, selectedDate }) => {
  const selectedEvents = orderBy(
    events?.filter((event) => {
      const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");

      return (
        event.startDate &&
        event.endDate &&
        formattedDate >= event.startDate &&
        formattedDate <= event.endDate
      );
    }),
    ["deletedAt", "startDate", "endDate"],
    ["desc", "asc", "asc"],
  );

  return (
    <>
      <Text>
        {selectedDate &&
          dayjs(selectedDate).locale("fr").format("dddd D MMMM YYYY")}{" "}
        :
      </Text>
      {selectedEvents.length ? (
        selectedEvents.map((event) => {
          const historyEvents = [...(event.history ?? []), event];
          const startDate = dayjs(event.startDate);
          const endDate = dayjs(event.endDate);

          const finalAdults = event.adults || 0;
          const finalChildren = event.children || 0;

          const family = [
            `${finalAdults || "?"} ${finalAdults === 1 ? "adulte" : "adultes"}`,
            finalChildren > 0
              ? `${finalChildren} ${finalChildren === 1 ? "enfant" : "enfants"}`
              : null,
          ].filter(Boolean);

          return (
            <Indicator
              key={event.id}
              label="Supprimée"
              size={20}
              color="red"
              disabled={!event.deletedAt}
              position="top-center"
              className={
                event.deletedAt
                  ? "opacity-40 max-w-sm w-full"
                  : "opacity-75 max-w-sm w-full"
              }
            >
              <Card withBorder>
                <Stack gap="xs">
                  <Text c="dimmed" fz="sm">
                    {startDate.locale("fr").format("ddd D MMM")} →{" "}
                    {endDate.locale("fr").format("ddd D MMM YYYY")}
                  </Text>
                  <Text fw={500}>{event.title}</Text>
                  <Group gap="xs">
                    <ThemeIcon variant="white">
                      <IconBed size="1rem" />
                    </ThemeIcon>
                    <Text fz="sm">
                      Apt{" "}
                      {event.flats?.length
                        ? joinStrings(sortBy(event.flats))
                        : "?"}
                    </Text>
                  </Group>
                  <Group gap="xs">
                    <ThemeIcon variant="white">
                      <IconFriends size="1rem" />
                    </ThemeIcon>
                    <Text fz="sm">{family.join(" & ")}</Text>
                  </Group>
                  <Divider />
                  <Group gap="xs">
                    <EditEventButton event={event} events={events} />
                    <HoverCard shadow="md" position="top">
                      <HoverCard.Target>
                        <ThemeIcon variant="light">
                          <IconInfoCircle size="1rem" />
                        </ThemeIcon>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Stack>
                          {historyEvents.map((subEvent, index) => {
                            if (subEvent.deletedAt) {
                              return (
                                <Text fz="xs" key={index}>
                                  Supprimé par {subEvent.deletedBy} le{" "}
                                  {subEvent.deletedAt
                                    ? dayjs(subEvent.deletedAt?.toDate())
                                        .locale("fr")
                                        .format("D MMM YYYY à HH:mm")
                                    : ""}
                                </Text>
                              );
                            }

                            if (subEvent.updatedAt) {
                              if (historyEvents[index - 1]?.deletedAt) {
                                return (
                                  <Text fz="xs" key={index}>
                                    Restauré par {subEvent.updatedBy} le{" "}
                                    {subEvent.updatedAt
                                      ? dayjs(subEvent.updatedAt?.toDate())
                                          .locale("fr")
                                          .format("D MMM YYYY à HH:mm")
                                      : ""}
                                  </Text>
                                );
                              }

                              return (
                                <Text fz="xs" key={index}>
                                  Modifié par {subEvent.updatedBy} le{" "}
                                  {subEvent.updatedAt
                                    ? dayjs(subEvent.updatedAt?.toDate())
                                        .locale("fr")
                                        .format("D MMM YYYY à HH:mm")
                                    : ""}
                                </Text>
                              );
                            }

                            return (
                              <Text fz="xs" key={index}>
                                Ajouté par {subEvent.createdBy} le{" "}
                                {subEvent.createdAt
                                  ? dayjs(subEvent.createdAt?.toDate())
                                      .locale("fr")
                                      .format("D MMM YYYY à HH:mm")
                                  : ""}
                              </Text>
                            );
                          })}
                        </Stack>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Group>
                </Stack>
              </Card>
            </Indicator>
          );
        })
      ) : (
        <Text c="dimmed" fz="sm">
          Aucun événement pour ce jour
        </Text>
      )}
    </>
  );
};

export default EventsList;
