import {
  Button,
  Group,
  Modal,
  MultiSelect,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  Timestamp,
  arrayUnion,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import { Event, auth, flats } from "../../../../../../config/firebase";
import dayjs from "dayjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { FC } from "react";
import { modals } from "@mantine/modals";
import { omit } from "lodash";
import { maxDate } from "../../../../../../config/date";
import { cleanTitle, joinStrings } from "../../../../../../utils/lib";

export interface EditEventModalProps {
  event: Event;
  events?: Event[];
  opened: boolean;
  close: () => void;
}

const EditEventModal: FC<EditEventModalProps> = ({
  event,
  events,
  opened,
  close,
}) => {
  const [user] = useAuthState(auth);
  const form = useForm({
    initialValues: {
      dates: [
        event.startDate ? dayjs(event.startDate).toDate() : null,
        event.endDate ? dayjs(event.endDate).toDate() : null,
      ],
      title: event.title ?? "",
      adults: event.adults ?? 2,
      children: event.children ?? 0,
      flats: event.flats ?? [],
    },

    validate: {
      dates: (value) =>
        value[0] && value[1] ? null : "Vous devez choisir une date",
      title: (value) =>
        cleanTitle(value).length ? null : "Le titre ne peut pas être vide",
    },
  });

  const [loading, { open: start, close: stop }] = useDisclosure(false);

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: "Supprimer l'événement",
      centered: true,
      children: (
        <Text size="sm">Êtes-vous sûr de vouloir supprimer l'événement ?</Text>
      ),
      labels: { confirm: "Supprimer", cancel: "Annuler" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        if (event.ref && user?.email) {
          start();
          updateDoc<Event, Event>(event.ref, {
            history: arrayUnion(omit(event, ["id", "ref", "history"])),
            deletedAt: Timestamp.now(),
            deletedBy: user.email,
          })
            .then(close)
            .finally(stop);
        }
      },
    });

  const openRestoreModal = () =>
    modals.openConfirmModal({
      title: "Restaurer l'événement",
      centered: true,
      children: (
        <Text size="sm">Êtes-vous sûr de vouloir restaurer l'événement ?</Text>
      ),
      labels: { confirm: "Restaurer", cancel: "Annuler" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        if (event.ref && user?.email) {
          start();
          updateDoc<Event, Event>(event.ref, {
            history: arrayUnion(omit(event, ["id", "ref", "history"])),
            deletedAt: deleteField(),
            deletedBy: deleteField(),
            updatedAt: Timestamp.now(),
            updatedBy: user.email,
          })
            .then(close)
            .finally(stop);
        }
      },
    });

  const occupiedFlats = form.values.flats.filter((flat) => {
    return events?.some((e) => {
      return (
        e.id !== event.id &&
        !e.deletedAt &&
        e.startDate &&
        e.endDate &&
        form.values.dates[0] &&
        form.values.dates[1] &&
        e.flats?.some((f) => f === flat) &&
        e.startDate < dayjs(form.values.dates[1]).format("YYYY-MM-DD") &&
        e.endDate > dayjs(form.values.dates[0]).format("YYYY-MM-DD")
      );
    });
  });

  return (
    <Modal
      opened={opened}
      onClose={close}
      title="Modifier l'événement"
      centered={true}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          if (event.ref && user?.email) {
            start();
            updateDoc<Event, Event>(event.ref, {
              history: arrayUnion(omit(event, ["id", "ref", "history"])),
              startDate: dayjs(values.dates[0]).format("YYYY-MM-DD"),
              endDate: dayjs(values.dates[1]).format("YYYY-MM-DD"),
              title: values.title,
              adults: values.adults || 0,
              children: values.children || 0,
              flats: values.flats,
              updatedAt: Timestamp.now(),
              updatedBy: user.email,
            })
              .then(close)
              .finally(stop);
          }
        })}
      >
        <Stack>
          <TextInput
            withAsterisk
            disabled={Boolean(loading || event.deletedAt)}
            label="Description"
            placeholder="Famille Sayer"
            {...form.getInputProps("title")}
          />
          <DatePickerInput
            level="month"
            withAsterisk
            allowSingleDateInRange
            disabled={Boolean(loading || event.deletedAt)}
            valueFormat="ddd D MMM YYYY"
            type="range"
            label="Dates"
            placeholder="sam. 23 déc. 2023 - sam. 6 janv. 2024"
            classNames={{
              placeholder: "text-[#adb5bd]",
            }}
            minDate={dayjs().subtract(maxDate.value, maxDate.unit).toDate()}
            maxDate={dayjs().add(maxDate.value, maxDate.unit).toDate()}
            {...form.getInputProps("dates")}
          />
          <Group>
            <NumberInput
              label="Adultes"
              disabled={Boolean(loading || event.deletedAt)}
              placeholder="2"
              min={0}
              {...form.getInputProps("adults")}
            />
            <NumberInput
              label="Enfants"
              disabled={Boolean(loading || event.deletedAt)}
              placeholder="0"
              min={0}
              {...form.getInputProps("children")}
            />
          </Group>
          <MultiSelect
            disabled={Boolean(loading || event.deletedAt)}
            label="Appartements"
            placeholder={form.values.flats.length ? "" : "33"}
            data={flats}
            classNames={{
              inputField: "placeholder:text-[#adb5bd]",
            }}
            {...form.getInputProps("flats")}
            error={
              occupiedFlats.length && !loading && !event.deletedAt
                ? occupiedFlats.length > 1
                  ? `Les appartements ${joinStrings(occupiedFlats)} sont occupés sur cette période`
                  : `L'appartement ${occupiedFlats[0]} est occupé sur cette période`
                : undefined
            }
          />
          <Group justify="space-between" wrap="nowrap">
            {event.deletedAt ? (
              <div />
            ) : (
              <Button
                variant="light"
                color="red"
                onClick={openDeleteModal}
                disabled={loading}
              >
                Supprimer
              </Button>
            )}
            <Group wrap="nowrap">
              <Button onClick={close} variant="default" disabled={loading}>
                Annuler
              </Button>
              {event.deletedAt ? (
                <Button
                  variant="light"
                  color="red"
                  onClick={openRestoreModal}
                  disabled={loading}
                >
                  Restaurer
                </Button>
              ) : (
                <Button type="submit" loading={loading}>
                  Modifier
                </Button>
              )}
            </Group>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditEventModal;
