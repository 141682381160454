import {
  Button,
  Group,
  Modal,
  MultiSelect,
  NumberInput,
  Stack,
  TextInput,
} from "@mantine/core";
import { DatePickerInput, DateValue } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { Event, Flat, auth, db, flats } from "../../../../../config/firebase";
import dayjs from "dayjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { FC } from "react";
import { maxDate } from "../../../../../config/date";
import { cleanTitle, joinStrings } from "../../../../../utils/lib";

export interface NewEventModalProps {
  opened: boolean;
  close: () => void;
  defaultDate: Date;
  events?: Event[];
}

const NewEventModal: FC<NewEventModalProps> = ({
  opened,
  close,
  defaultDate,
  events,
}) => {
  const [user] = useAuthState(auth);
  const form = useForm<{
    dates: [DateValue, DateValue];
    title: string;
    adults: number;
    children: number;
    flats: Flat[];
  }>({
    initialValues: {
      dates: [null, null],
      title: "",
      adults: 2,
      children: 0,
      flats: [],
    },

    validate: {
      dates: (value) =>
        value[0] && value[1] ? null : "Vous devez choisir une date",
      title: (value) =>
        cleanTitle(value).length ? null : "Le titre ne peut pas être vide",
    },
  });

  const [loading, { open: start, close: stop }] = useDisclosure(false);

  const occupiedFlats = form.values.flats.filter((flat) => {
    return events?.some((event) => {
      return (
        !event.deletedAt &&
        event.startDate &&
        event.endDate &&
        form.values.dates[0] &&
        form.values.dates[1] &&
        event.flats?.some((f) => f === flat) &&
        event.startDate < dayjs(form.values.dates[1]).format("YYYY-MM-DD") &&
        event.endDate > dayjs(form.values.dates[0]).format("YYYY-MM-DD")
      );
    });
  });

  return (
    <Modal
      opened={opened}
      onClose={close}
      title="Nouvel événement"
      centered={true}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          if (user?.email) {
            start();
            addDoc<Event, Event>(collection(db, "events"), {
              startDate: dayjs(values.dates[0]).format("YYYY-MM-DD"),
              endDate: dayjs(values.dates[1]).format("YYYY-MM-DD"),
              title: cleanTitle(values.title),
              adults: values.adults || 0,
              children: values.children || 0,
              flats: values.flats,
              createdAt: Timestamp.now(),
              createdBy: user.email,
            })
              .then(close)
              .finally(stop);
          }
        })}
      >
        <Stack>
          <TextInput
            withAsterisk
            disabled={loading}
            label="Description"
            placeholder="Famille Sayer"
            classNames={{
              input: "placeholder:text-[#adb5bd]",
            }}
            {...form.getInputProps("title")}
          />
          <DatePickerInput
            defaultDate={defaultDate}
            allowSingleDateInRange
            level="month"
            withAsterisk
            disabled={loading}
            valueFormat="ddd D MMM YYYY"
            type="range"
            label="Dates"
            placeholder="sam. 23 déc. 2023 - sam. 6 janv. 2024"
            classNames={{
              placeholder: "text-[#adb5bd]",
            }}
            minDate={dayjs().subtract(maxDate.value, maxDate.unit).toDate()}
            maxDate={dayjs().add(maxDate.value, maxDate.unit).toDate()}
            {...form.getInputProps("dates")}
          />
          <Group>
            <NumberInput
              label="Adultes"
              disabled={loading}
              placeholder="2"
              min={0}
              {...form.getInputProps("adults")}
            />
            <NumberInput
              label="Enfants"
              disabled={loading}
              placeholder="0"
              min={0}
              {...form.getInputProps("children")}
            />
          </Group>
          <MultiSelect
            disabled={loading}
            label="Appartements"
            placeholder={form.values.flats.length ? "" : "33"}
            data={flats}
            {...form.getInputProps("flats")}
            error={
              occupiedFlats.length && !loading
                ? occupiedFlats.length > 1
                  ? `Les appartements ${joinStrings(occupiedFlats)} sont occupés sur cette période`
                  : `L'appartement ${occupiedFlats[0]} est occupé sur cette période`
                : undefined
            }
          />
          <Group justify="space-between" wrap="nowrap">
            <div />
            <Group wrap="nowrap">
              <Button onClick={close} variant="default" disabled={loading}>
                Annuler
              </Button>
              <Button type="submit" loading={loading}>
                Valider
              </Button>
            </Group>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default NewEventModal;
