import { collection, query, where } from "firebase/firestore";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Event, db, firestoreConverter } from "../../../config/firebase";
import { Stack } from "@mantine/core";
import NewEventButton from "./NewEventButton";
import EventsList from "./EventsList";
import CalendarCard from "./CalendarCard";
import dayjs from "dayjs";
import { maxDate } from "../../../config/date";

const Main = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  const [events, loading] = useCollectionData<Event>(
    query(
      collection(db, "events"),
      where(
        "endDate",
        ">",
        dayjs().subtract(maxDate.value, maxDate.unit).format("YYYY-MM-DD"),
      ),
    ).withConverter(firestoreConverter),
  );

  return (
    <Stack align="center">
      <NewEventButton defaultDate={calendarDate} events={events} />
      <CalendarCard
        events={events}
        loading={loading}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        calendarDate={calendarDate}
        setCalendarDate={setCalendarDate}
      />
      <EventsList selectedDate={selectedDate} events={events} />
    </Stack>
  );
};

export default Main;
